import React from "react";
import PropTypes from "prop-types";
import classes from "./Button.module.css";
import Spinner from "../../../assets/spinner.inline.svg";

const Button = ({ children, onClick, loading, disabled }) => {
    return (
        <button onClick={onClick} disabled={disabled} className={classes.ButtonWrapper}>
            {loading ? <Spinner /> : children}
        </button>
    );
};

Button.propTypes = {
    children: PropTypes.any,
};

export default Button;
