import React from "react";
import Title from "../../UI/Title/Title";
import DropDown from "../Dropdown/Dropdown";
import DatePicker from "../DatePicker/DatePicker";
import TimePicker from "../TimePicker/TimePicker";
import NumericStepper from "../NumericStepper/NumericStepper";
import Button from "../Button/Button";

//hooks, libraries, css
import PlacesAutocomplete from "react-places-autocomplete";
import { useLocationDropdown } from "../../../hooks/useLocationDropdown";
import classes from "./BookTransfer.module.css";

//assets
import DownArrow from "../../../assets/icons/down-arrow.inline.svg";
import { VscCircleOutline } from "react-icons/vsc";
import { BiCurrentLocation } from "react-icons/bi";

const searchOptions = {
    componentRestrictions: {
        // types: 'address',
        country: "gr",
    },
};

//todo add validation && when the pages opens the form reforms > need to have preoccupied area and a spinner until is ready
//todo also in validation check from and not must no be equal
const Transfers = ({
    luggageCount,
    onLuggageAdd,
    onLuggageRemove,
    passengersCount,
    onPassengerAdd,
    onPassengerRemove,
    onSubmit,
    onDateSelect,
    onPickupTimeSelect,
    selectedDate,
    timeSelectInterval,
    onFromOptionSelect,
    onToOptionSelect,
    defaultFromOption,
    defaultToOption,
    defaultOptions,
    loading,
    disabledContinueButton,
    continueButtonText,
    header,
}) => {
    const [fromQuery, onFromChange, onFromSelect, onFromClear] = useLocationDropdown(onFromOptionSelect, defaultFromOption);
    const [toQuery, onToChange, onToSelect, onToClear] = useLocationDropdown(onToOptionSelect, defaultToOption);

    const uniq_fast = (a) => {
        var seen = {};
        var out = [];
        var len = a.length;
        var j = 0;
        for (var i = 0; i < len; i++) {
            var item = a[i];
            if (seen[item.description] !== 1) {
                seen[item.description] = 1;
                out[j++] = item;
            }
        }
        return out;
    };

    return (
        <div className={classes.ToursWrapper}>
            <div className={classes.TitleWrapper}>
                <Title>{header ? header : "Book a transfer"}</Title>
            </div>
            <div className={classes.LocationInputsWrapper}>
                <PlacesAutocomplete
                    searchOptions={searchOptions}
                    debounce={600}
                    value={fromQuery.value}
                    onChange={onFromChange}
                    onSelect={onFromSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                        let allSuggestions = [...suggestions];

                        defaultOptions.forEach((e) => {
                            if (e.description.includes(fromQuery.value)) {
                                allSuggestions.unshift(e);
                            } else {
                                allSuggestions.push(e);
                            }
                        });

                        //remove the duplicates
                        allSuggestions = uniq_fast(allSuggestions);

                        return (
                            <DropDown
                                getSuggestionItemProps={getSuggestionItemProps}
                                getInputProps={getInputProps}
                                options={allSuggestions}
                                loading={loading}
                                placeholder="From (airport, port, address)"
                                leftIcon={VscCircleOutline}
                                displayX={fromQuery && fromQuery.value && fromQuery.value.length > 0}
                                clear={onFromClear}
                                //smallLeftIcon
                            />
                        );
                    }}
                </PlacesAutocomplete>
                <PlacesAutocomplete
                    searchOptions={searchOptions}
                    debounce={600}
                    value={toQuery.value}
                    onChange={onToChange}
                    onSelect={onToSelect}
                >
                    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => {
                        let allSuggestions = [...suggestions];

                        defaultOptions.forEach((e) => {
                            if (e.description.includes(toQuery.value)) {
                                allSuggestions.unshift(e);
                            } else {
                                allSuggestions.push(e);
                            }
                        });

                        //remove the duplicates
                        allSuggestions = uniq_fast(allSuggestions);

                        return (
                            <DropDown
                                getSuggestionItemProps={getSuggestionItemProps}
                                getInputProps={getInputProps}
                                options={allSuggestions}
                                loading={loading}
                                placeholder="To (airport, port, adddress)"
                                leftIcon={BiCurrentLocation}
                                displayX={toQuery && toQuery.value && toQuery.value.length > 0}
                                clear={onToClear}
                                //smallLeftIcon
                            />
                        );
                    }}
                </PlacesAutocomplete>
                <span className={classes.DirectionIcon}>
                    <DownArrow />
                </span>
            </div>
            <div className={classes.CellWrapper}>
                <Title small>Pickup date</Title>
                <DatePicker minDate={new Date()} date={selectedDate} setDate={onDateSelect} />
            </div>
            <div className={classes.CellWrapper}>
                <Title small>Pickup time</Title>
                <TimePicker minDate={new Date()} interval={timeSelectInterval} date={selectedDate} setDate={onPickupTimeSelect} />
            </div>
            <div className={classes.CellWrapper}>
                <Title small>Passengers</Title>
                <NumericStepper counter={passengersCount} onIncrease={onPassengerAdd} onDecrease={onPassengerRemove} />
            </div>
            <div className={classes.CellWrapper}>
                <Title small>Luggage pieces</Title>
                <NumericStepper counter={luggageCount} onDecrease={onLuggageRemove} onIncrease={onLuggageAdd} />
            </div>
            <div className={classes.SubmitButtonWrapper}>
                <Button disabled={disabledContinueButton} loading={loading} onClick={onSubmit}>
                    {continueButtonText ? continueButtonText : "Continue"}
                </Button>
            </div>
        </div>
    );
};

export default Transfers;
