import styled from "styled-components";

const StyledDiv = styled.div`
    &.MainWrapper {
        position: fixed;
        top: 50%;
        left: 50%;
        z-index: 10;
        background-color: #fff;
        transform: translate(-50%, -50%);
        max-height: 95%;
        overflow-y: scroll;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.55);
        border-radius: var(--radius);
        width: 95%;
        max-width: 800px;
        padding: 1.5%;

        &.small {
            max-width: 500px;
        }
    }
    &.CarsWrapper {
        display: grid;
        grid-auto-rows: auto;
        grid-gap: 1rem;
        margin: auto;
        padding-bottom: 1rem;
    }

    @media (max-width: 800px) {
        &.Wrapper {
            width: 95%;
            min-width: auto;
        }
        &.CarsWrapper {
            width: 100%;
            min-width: auto;
        }
    }
`;

export { StyledDiv };
