import React from "react";
import classes from "./DropDownItem.module.css";
import ShipIcon from "../../../../assets/icons/ship.inline.svg";
import TrainIcon from "../../../../assets/icons/train.inline.svg";
import PlaneIcon from "../../../../assets/icons/plane.inline.svg";
import PlaceIcon from "../../../../assets/icons/place.inline.svg";
import PropTypes from "prop-types";

const DropdownItem = ({ title, description, types = [], ...rest }) => {
    let Icon = PlaceIcon;
    if (types.includes("airport")) {
        Icon = PlaneIcon;
    } else if (types.includes("train_station")) {
        Icon = TrainIcon;
    } else if (types.includes("harbour")) {
        Icon = ShipIcon;
    }
    return (
        <div className={classes.Wrapper} {...rest}>
            <div className={classes.IconWrapper}>
                <Icon />
            </div>
            <div className={classes.InformationWrapper}>
                <h4 className={classes.Title}>{title}</h4>
                <p className={classes.Paragraph}>{description}</p>
            </div>
        </div>
    );
};

DropdownItem.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
};

export default DropdownItem;
