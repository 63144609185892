import React from "react";
import classes from "./NumericStepper.module.css";
import PlusIcon from "../../../assets/icons/plus.inline.svg";
import MinusIcon from "../../../assets/icons/minus.inline.svg";
import PropTypes from "prop-types";

const NumericStepper = ({ counter = "0", onIncrease, onDecrease, disableLeft, disableRight }) => {
    return (
        <div className={classes.Wrapper}>
            <button className={classes.NumericStepperButtonLeft} disabled={disableLeft} onClick={onDecrease}>
                <MinusIcon />
            </button>
            <span className={classes.NumericStepperSpan}>{counter}</span>
            <button className={classes.NumericStepperButtonRight} disabled={disableRight} onClick={onIncrease}>
                <PlusIcon />
            </button>
        </div>
    );
};

NumericStepper.propTypes = {
    counter: PropTypes.number,
    onIncrease: PropTypes.func,
    onDecrease: PropTypes.func,
    disableLEft: PropTypes.bool,
    disableRight: PropTypes.bool,
};

export default NumericStepper;
