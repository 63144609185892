import React, { useState } from "react";
import Transfers from "../BookingForm/BookTransfer/Transfers";
import Backdrop from "../UI/Backdrop/Backdrop";
import { StyledDiv } from "./BookingFormModalStyled";
import moment from "moment";
import axios from "axios";
import { graphql, useStaticQuery } from "gatsby";
import VehicleCard from "../CarCard/VehicleCard";
import Title from "../UI/Title/Title";
import { roundUpTo } from "../../utils/helperFunctions";
import { REACT_APP_REST_RESOURCE_BASE_END_POINT } from "../../constants/apiEndpoints";


//this is time interval used in time picker
const TIME_SELECT_INTERVAL = 5;

const BookingFormModal = ({ defaultState, show, onClose, onSubmit, defaultOptions = [], checkoutData, blockCloseOnSubmit, title }) => {
    const [state, setState] = useState(
        defaultState
            ? defaultState
            : {
                  passengersCount: 1,
                  luggageCount: 1,
                  date: roundUpTo(TIME_SELECT_INTERVAL),
              }
    );
    const [cars, setCars] = useState(null);
    const [loading, setLoading] = useState(false);
    const data = useStaticQuery(query);
    const {
        allStrapiVehicles: { nodes: vehicles },
    } = data;

    const onLuggageRemove = () => {
        setState((prevState) => {
            if (prevState.luggageCount - 1 >= 1) {
                return {
                    ...prevState,
                    luggageCount: prevState.luggageCount - 1,
                };
            } else if (!prevState || !prevState.luggageCount) {
                return {
                    ...prevState,
                    luggageCount: 0,
                };
            }
            return prevState;
        });
    };
    const onLuggageAdd = () => {
        setState((prevState) => {
            if (prevState.luggageCount + 1 <= 10) {
                return {
                    ...prevState,
                    luggageCount: prevState.luggageCount + 1,
                };
            } else if (!prevState || !prevState.luggageCount) {
                return {
                    ...prevState,
                    luggageCount: 1,
                };
            }
            return prevState;
        });
    };
    const onPassengersRemove = () => {
        setState((prevState) => {
            if (prevState.passengersCount - 1 >= 1) {
                return {
                    ...prevState,
                    passengersCount: prevState.passengersCount - 1,
                };
            } else if (!prevState || !prevState.passengersCount) {
                return {
                    ...prevState,
                    passengersCount: 0,
                };
            }
            return prevState;
        });
    };
    const onPassengersAdd = () => {
        setState((prevState) => {
            if (prevState.passengersCount + 1 <= 10) {
                return {
                    ...prevState,
                    passengersCount: prevState.passengersCount + 1,
                };
            } else if (!prevState || !prevState.passengersCount) {
                return {
                    ...prevState,
                    passengersCount: 1,
                };
            }
            return prevState;
        });
    };

    const onDateSelect = (e) => {
        setState((prevState) => ({
            ...prevState,
            date: e,
        }));
    };

    const onFromSelect = (e) => {
        setState((prevState) => ({
            ...prevState,
            fromData: {
                ...prevState.fromData,
                ...e,
            },
        }));
    };
    const onToSelect = (e) => {
        setState((prevState) => ({
            ...prevState,
            toData: {
                ...prevState.toData,
                ...e,
            },
        }));
    };

    const onUpdateClick = () => {
        const transferDateTime = moment(state.date);
        const stripedDateTime = transferDateTime.format("YYYY-MM-DDTHH:mm:ss");

        let data = JSON.stringify({
            originCoordinates: state.fromData.latLng,
            destinationCoordinates: state.toData.latLng,
            originData: state.fromData.e,
            destinationData: state.toData.e,
            transferDateTime: stripedDateTime,
        });

        setLoading(true);

        axios.post(`${REACT_APP_REST_RESOURCE_BASE_END_POINT}/prices`, data, {
                headers: { "Content-Type": "application/json" },
            })
            .then((r) => {
                setLoading(false);
                const cars = [];
                vehicles.map((vehicle) => {
                    const { id, amenities, title, description, maxLuggage, maxPeople, image, enumCategory, type } = vehicle;
                    const fluid = image.childImageSharp.fluid;

                    if (r.data && r.data.length > 0) {
                        const vehicleData = r.data.find((e) => e.vehicleCategory === enumCategory);

                        let vehiclePrice = null;
                        if (vehicleData && vehicleData.price) {
                            vehiclePrice = vehicleData.price;
                        }

                        if (state.luggageCount <= maxLuggage && state.passengersCount <= maxPeople) cars.push({ ...vehicle, vehiclePrice });
                    }
                });

                const vehicle = cars.find((e) => e.id === checkoutData.vehicleId);

                if (cars && vehicle) {
                    //close the modal and set the data
                    onSubmit(
                        state,
                        vehicle.type,
                        vehicle.vehiclePrice,
                        vehicle.maxLuggage,
                        vehicle.maxPeople,
                        vehicle.id,
                        vehicle.enumCategory
                    );
                    onModalClose();
                } else {
                    setCars(cars);
                }
            })
            .catch((err) => {
                //display the error to the user
                alert(err);
                setLoading(false);
            });
    };

    const onModalClose = () => {
        setCars(null);
        if (!blockCloseOnSubmit) onClose();
    };

    if (!show) return null;

    return (
        <>
            <StyledDiv className={`MainWrapper ${!cars ? "small" : ""}`}>
                {!cars ? (
                    <StyledDiv className="Wrapper">
                        <Transfers
                            defaultFromOption={
                                state && state.fromData && state.fromData.e && state.fromData.e.description
                                    ? state.fromData.e.description
                                    : null
                            }
                            defaultToOption={
                                state && state.toData && state.toData.e && state.toData.e.description ? state.toData.e.description : null
                            }
                            luggageCount={state && state.luggageCount ? state.luggageCount : 1}
                            onLuggageAdd={onLuggageAdd}
                            onLuggageRemove={onLuggageRemove}
                            passengersCount={state && state.passengersCount ? state.passengersCount : 1}
                            onPassengerAdd={onPassengersAdd}
                            onPassengerRemove={onPassengersRemove}
                            onSubmit={() => onUpdateClick()}
                            onDateSelect={onDateSelect}
                            onPickupTimeSelect={onDateSelect}
                            selectedDate={state && state.date ? state.date : new Date()}
                            timeSelectInterval={5}
                            onFromOptionSelect={onFromSelect}
                            onToOptionSelect={onToSelect}
                            defaultOptions={defaultOptions}
                            loading={loading}
                            disabledContinueButton={
                                (state && state.fromData && state.fromData.success ? !state.fromData.success : true) ||
                                (state && state.toData && state.toData.success ? !state.toData.success : true)
                            }
                            continueButtonText="Continue"
                            header={title ? title : "Update transfer data"}
                        />
                    </StyledDiv>
                ) : cars.length > 0 ? (
                    <StyledDiv className="CarsWrapper">
                        {cars.map((vehicle) => {
                            return (
                                <VehicleCard
                                    key={vehicle.id}
                                    fluid={vehicle.image.childImageSharp.fluid}
                                    category={vehicle.title}
                                    description={vehicle.description}
                                    maxLuggage={vehicle.maxLuggage}
                                    amenities={vehicle.amenities}
                                    maxPeople={vehicle.maxPeople}
                                    price={vehicle.vehiclePrice}
                                    onSelect={() => {
                                        onSubmit(
                                            state,
                                            vehicle.type,
                                            vehicle.vehiclePrice,
                                            vehicle.maxLuggage,
                                            vehicle.maxPeople,
                                            vehicle.id,
                                            vehicle.enumCategory
                                        );
                                        onModalClose();
                                    }}
                                />
                            );
                        })}
                    </StyledDiv>
                ) : (
                    <Title>No available cars</Title>
                )}
            </StyledDiv>
            <Backdrop onClick={onModalClose} show />
        </>
    );
};

const query = graphql`
    {
        allStrapiVehicles {
            nodes {
                id
                title
                description
                maxLuggage
                maxPeople
                enumCategory
                amenities {
                    amenity
                    icon
                }
                image {
                    childImageSharp {
                        fluid(quality: 100) {
                            ...GatsbyImageSharpFluid
                            ...GatsbyImageSharpFluidLimitPresentationSize
                        }
                    }
                }
            }
        }
    }
`;

export default BookingFormModal;
