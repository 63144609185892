import React from "react";
import { InputWithRef } from "../../UI/Input/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../../assets/icons/calendar.inline.svg";

const CustomDatePicker = ({ date, setDate, minDate = null }) => {
    const ref = React.createRef();

    return (
        <DatePicker
            minDate={minDate}
            selected={new Date(date)}
            onChange={setDate}
            customInput={<InputWithRef ref={ref} rightIcon={CalendarIcon} readOnly />}
        ></DatePicker>
    );
};

export default CustomDatePicker;
