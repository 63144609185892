import React from "react";
import classes from "./Title.module.css";

const Title = ({ children, small, medium, style = {} }) => {
    return (
        <h1 style={style} className={[classes.Title, small ? classes.small : null, medium ? classes.medium : null].join(" ")}>
            {children}
        </h1>
    );
};

export default Title;
