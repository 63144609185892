import styled from "styled-components";

const StyledDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    cursor: pointer;

    &.top {
        z-index: 998;
    }
`;

export { StyledDiv };
