import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";
import classes from "./Input.module.css";

const Input = ({
    placeholder,
    leftIcon,
    rightIcon,
    value,
    onClick,
    smallLeftIcon,
    rightIconClick,
    onFocus,
    onBlur,
    customRef,
    error,
    ...rest
}) => {
    const [focused, setFocused] = useState(false);
    const LeftIcon = leftIcon;
    const RightIcon = rightIcon;

    const onInputFocus = () => {
        setFocused(true);
        if (onFocus) onFocus();
    };

    const onInputBlur = () => {
        setFocused(false);
        if (onBlur) onBlur();
    };

    return (
        <div
            onClick={onClick}
            className={[
                classes.Wrapper,
                smallLeftIcon ? classes.smallLeftIcon : null,
                focused ? classes.focused : null,
                error ? classes.error : null,
            ].join(" ")}
        >
            {leftIcon ? <LeftIcon /> : null}
            <input
                {...rest}
                onBlur={onInputBlur}
                onFocus={onInputFocus}
                value={value}
                placeholder={error ? error : placeholder}
                ref={customRef}
            />
            {rightIcon ? <RightIcon style={rightIconClick ? { cursor: "pointer" } : {}} onClick={rightIconClick} /> : null}
        </div>
    );
};

Input.propTypes = {
    placeholder: PropTypes.string,
    rightIcon: PropTypes.any,
    leftIcon: PropTypes.any,
};

const InputWithRef = forwardRef((props, ref) => {
    return <Input {...props} customRef={ref}></Input>;
});

export default Input;

export { InputWithRef };
