import React from "react";
import { StyledDiv } from "./BackdropStyled";
import PropTypes from "prop-types";

const Backdrop = ({ show, onClick, top }) => {
    return show ? <StyledDiv className={`${top ? "top" : ""} Backdrop`} onClick={onClick} /> : null;
};

Backdrop.propTypes = {
    show: PropTypes.bool,
    onClick: PropTypes.func,
    top: PropTypes.bool,
};

export default Backdrop;
