import React from "react";
import DatePicker from "react-datepicker";
import { InputWithRef } from "../../UI/Input/Input";
import ClockIcon from "../../../assets/icons/clock.inline.svg";

const TimePicker = ({ date, setDate, interval, minDate = null }) => {
    const ref = React.createRef();
    return (
        <DatePicker
            minDate={minDate}
            selected={new Date(date)}
            onChange={setDate}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={interval}
            timeCaption="Time"
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            customInput={<InputWithRef ref={ref} readOnly rightIcon={ClockIcon} />}
        />
    );
};

export default TimePicker;
