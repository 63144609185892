import React, { useState } from "react";
import Input from "../../UI/Input/Input";
import PropTypes from "prop-types";
import classes from "./Dropdown.module.css";
import DropdownItem from "./DropdownItem/DropdownItem";
import CloseIcon from "../../../assets/icons/close.inline.svg";
import PoweredByGoogle from "../../../assets/poweredByGoogleLogo.png";

const Dropdown = ({ getInputProps, leftIcon, placeholder, smallLeftIcon, options, getSuggestionItemProps, onClick, clear, displayX }) => {
    const [show, setShow] = useState(false);

    const onFocus = () => {
        setShow(true);
    };

    const onBlur = () => {
        setShow(false);
    };

    const onSelect = (address, lanLng) => {
        setShow(false);
    };

    return (
        <div className={classes.Wrapper}>
            <form autoComplete="off">
                <Input
                    autoComplete="off"
                    {...getInputProps({
                        placeholder: placeholder,
                        onFocus: onFocus,
                        onBlur: onBlur,
                    })}
                    type="text"
                    smallLeftIcon={smallLeftIcon}
                    leftIcon={leftIcon}
                    rightIcon={displayX ? CloseIcon : null}
                    rightIconClick={clear}
                />
            </form>

            <div className={[classes.DropdownWrapper, show ? classes.show : null].join(" ")}>
                {show &&
                    options.map((option, index) => {
                        return (
                            <DropdownItem
                                {...getSuggestionItemProps(option, {
                                    onClick: () => onSelect(option.address, option.latLng),
                                })}
                                types={option.types}
                                key={index}
                                title={option.formattedSuggestion.mainText}
                                description={option.formattedSuggestion.secondaryText}
                            />
                        );
                    })}
                <div className={classes.PoweredByGoogleLogoWrapper}>
                    <img alt="powered by google" src={PoweredByGoogle} />
                </div>
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    selectedValue: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
};

export default Dropdown;
