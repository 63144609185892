import { useState } from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";

export const useLocationDropdown = (onOptionSelect, defaultValue = "") => {
    const [value, setValue] = useState({ value: defaultValue, valid: false });

    const onChange = (address) => {
        setValue({ value: address, valid: false });
        onOptionSelect({ success: false, latLng: { lat: null, lng: null } });
    };

    const onSelect = (address, latLng, e) => {
        setValue({ value: address, valid: true });

        // console.log(e);

        if (e.latLng && e.latLng.lat && e.latLng.lng) {
            onOptionSelect({ success: true, latLng: { lat: e.latLng.lat, lng: e.latLng.lng }, e });
        } else {
            geocodeByAddress(address)
                .then((results) => getLatLng(results[0]))
                .then((latLng) => onOptionSelect({ success: true, latLng, e }))
                .catch((error) => onOptionSelect({ success: false, error }));
        }
    };

    const onClear = () => {
        setValue({ value: "", valid: false });
        onOptionSelect({ success: false, latLng: { lat: null, lng: null } });
    };

    return [value, onChange, onSelect, onClear];
};
