export const defaultOptions = [
    {
        formattedSuggestion: { mainText: "Piraeus Port", secondaryText: "Piraeus Port (All ferries and Cruises)" },
        description: "Piraeus Port", //this is used for the address of the place, if latLng is not specified it will try to fetch teh latLng using this value
        latLng: {
            lat: 37.9351377,
            lng: 23.624673,
        },
        types: ["harbour"], //supported types: airport, harbour, train_station, default icon is the places.svg icon
    },
    {
        formattedSuggestion: {
            mainText: "Athens International Airport (ATH)",
            secondaryText: 'Athens International Airport "Eleftherios Venizelos", Greece',
        },
        description: "Athens International Airport Eleftherios Venizelos (ATH), Attiki Odos, Spata-Artemida, Greece",
        latLng: {
            lat: 37.93564670000001,
            lng: 23.9484156,
        },
        types: ["airport"],
    },
];
